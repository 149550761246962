import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    title: _ctx.regionId ? 'Edit Region' : 'Add Region',
    centered: "",
    visible: _ctx.visible,
    "cancel-text": "Cancel",
    "ok-text": "Confirm",
    onOk: _ctx.handlerConfirm,
    onCancel: _ctx.handlerCancel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        autocomplete: "off",
        model: _ctx.formData,
        "label-col": _ctx.labelCol,
        "wrapper-col": _ctx.wrapperCol,
        rules: _ctx.rules,
        ref: "formRef"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            label: "Business Unit",
            name: "buId"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: _ctx.buId,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.buId) = $event)),
                disabled: _ctx.regionId != ''
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bu, (item) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      value: item.id,
                      key: item.id
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.nameCn), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value", "disabled"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "Region En",
            name: "regionEn"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.regionEn,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.regionEn) = $event)),
                placeholder: ""
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "Region Cn",
            name: "regionCn"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.regionCn,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.regionCn) = $event)),
                placeholder: ""
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "Sub RegionEn",
            name: "subRegionEn"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.subRegionEn,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.subRegionEn) = $event)),
                placeholder: ""
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "Sub RegionCn",
            name: "subRegionCn"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.subRegionCn,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.subRegionCn) = $event)),
                placeholder: ""
              }, null, 8, ["value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "label-col", "wrapper-col", "rules"])
    ]),
    _: 1
  }, 8, ["title", "visible", "onOk", "onCancel"]))
}